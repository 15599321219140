<template>
  <div>
    <!-- 首页-轮播图 -->
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="https://www.wanshunfilm.net/images/jdbanner-1.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
      </div>
    </div>
    <div class="ys clear">
      <div class="content">
        <h2>每个元素皆为极致保护而生</h2>
        <p>一次装贴十年新车</p>
        <ul>
          <li>
            <img src="../assets/img/y1.png" />
            <h3>强耐污防腐蚀</h3>
            <p>
              鸟屎、空气污染、污迹、汽车漆面保护膜<br />
              提供全面的保护 ,污迹绝对不会残留
            </p>
          </li>
          <li>
            <img src="../assets/img/y2.png" />
            <h3>自动修复功能</h3>
            <p>
              全球首创ULTIMATEATE划痕自动还原<br />
              有效自动修复轻微划痕与旋涡状划纹
            </p>
          </li>
          <li>
            <img src="../assets/img/y3.png" />
            <h3>8.5mil加厚</h3>
            <p>
              8.5-11mil加厚更好保护车漆<br />
              用料更厚实，保护更持久
            </p>
          </li>
          <li>
            <img src="../assets/img/y4.png" />
            <h3>DAP专车专用</h3>
            <p>
              10万+的专车版型，0.22CM的切割精度<br />
              不拆车、不动刀、全自动电脑切割。
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="about">
      <div class="content">
        <div class="about-mes">
          <div class="row">
            <div class="clearfix">
              <div class="ab-left">
                <img
                  src="../assets/img/ab-left_03.jpg"
                  style="width:100%"
                />
              </div>
              <div>
                <div class="ab-right">
                  <div class="clearfix ab-right-tit">
                    <h3 class="fl">关于我们</h3>
                    <div class="fr"></div>
                  </div>
                  <small>从心出发，用心创造美好生活</small>
                  <div class="sbsb">
                    <p>
                      Ai•Li是新一代TPU漆面保护膜的品牌，Ai•Li坚持以客户为中心，为车主提供产品，施工，服务，Ai•Li隐形车衣是一款用于漆面保护的透明膜产品，应用于汽车、摩托车、房车、机动车、自行车、游艇、飞机等；围绕“优质产品、市场保护、价格管控、培训体系、增值服务”为核心，为经销商提供品牌运营保障。<br />
                      <b style="font-size: 18px; color: #ffcc00"
                        >咨询电话：18045057555</b
                      >
                    </p>
                  </div>
                  <a class="more" href="/contact">联系我们 &gt;&gt;</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
<style scoped>
.ys {
  padding-bottom: 50px;
  background-color: #fff;
  padding-top: 50px;
}
.clear {
  clear: both;
}
.clear::after {
  content: ".";
  height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.ys .content ul li {
  float: left;
  width: 300px;
  margin-top: 50px;
}
li {
  list-style: none;
  font-style: normal;
  outline: none;
}
.content {
  width: 1200px;
  margin: 0 auto;
}
ul,
li {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.ys .content > p {
  font-size: 32px;
  text-align: center;
  color: #000;
  margin-top: 25px;
}
.ys h2 {
  font-size: 32px;
  text-align: center;
  color: #ffcc00;
}
h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
body,
ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
table,
td,
img,
div,
tr,
dl,
dd,
select,
option {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li,
img,
input,
em,
select {
  list-style: none;
  font-style: normal;
  outline: none;
}
.about {
  background: #000000;
  padding: 50px 0;
}
.content {
  width: 1200px;
  margin: 0 auto;
}
.about-mes {
  height: 348px;
}
.ab-left {
  position: relative;
  float: left;
  left: 85px;
  top: -5px;
}
img {
  border: none;
}
.ab-right {
  width: 600px;
  float: right;
}
.ab-right > small {
  font-size: 12px;
  color: #666666;
  display: inline-block;
  margin: 10px 0 20px 0;
}
.ab-right .sbsb {
  line-height: 2;
  font-size: 15px;
  color: white;
}
.ab-right > a {
  display: inline-block;
  padding: 4px 40px;
  border: 1px solid white;
  border-radius: 15px;
  color: white;
  margin-top: 20px;
}
.ab-right-tit > h3 {
  font-size: 36px;
  color: #f1bf1e;
  text-transform: uppercase;
}
.ab-right-tit > div {
  height: 6px;
  width: calc(100% - 170px);
  border-bottom: 1px solid #79600f;
  border-top: 1px solid #79600f;
  margin-top: 26px;
}
.ab-right-tit > h3 {
  font-size: 36px;
  color: #f1bf1e;
  text-transform: uppercase;
}
.fl {
  float: left;
}
.ab-right-tit > div {
  height: 6px;
  width: calc(100% - 170px);
  border-bottom: 1px solid #79600f;
  border-top: 1px solid #79600f;
  margin-top: 26px;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.fr {
  float: right;
}
.ab-right > small {
  font-size: 12px;
  color: #666666;
  display: inline-block;
  margin: 10px 0 20px 0;
}
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}
.clearfix {
  zoom: 1;
}
div {
  display: block;
}
* {
  margin: 0;
  padding: 0;
}
</style>